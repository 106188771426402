'use client'

import 'client-only'
import i18next, { type LanguageDetectorModule } from 'i18next'
import { initReactI18next, useTranslation as useTranslationOrg } from 'react-i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import Cookie from 'js-cookie'
import { getOptions, languages } from './settings'

const runsOnServerSide = typeof window === 'undefined'

const languageDetectorPlugin: LanguageDetectorModule = {
  type: 'languageDetector',
  detect() {
    return Cookie.get('lang') || 'en'
  }
}


// Build instance
void i18next
  .use(initReactI18next)
  .use(languageDetectorPlugin)
  .use(resourcesToBackend((
    language: string,
    namespace: string
  ) => import(`../../locales/${language}/${namespace}.json`)))
  .init({
    ...getOptions(),
    lng: undefined, // let detect the language on client side
    detection: {
      order: ['path', 'htmlTag', 'cookie', 'navigator'],
    },
    preload: runsOnServerSide ? languages : []
  })

export const i18n = i18next

export function useTranslation(
  ns: string = 'common',
  options: { keyPrefix?: string } = {}
) {
  return useTranslationOrg(ns, options)
}
