'use client'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import invalidationQueryKey from 'app/api/auth/getAuthInfo/_helper/queryKey'
import navigateAfterSignInAction from './navigateAfterSignInAction'

const MUTATION_SCOPE = { id: 'navigateAfterSignInAction' }

function useNavigateAfterSignIn() {
  const queryClient = useQueryClient()

  return useMutation({
    gcTime: 0,
    scope: MUTATION_SCOPE,
    mutationFn: navigateAfterSignInAction,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: invalidationQueryKey })
    },
  })
}

export default useNavigateAfterSignIn
