import type { GetResponseData } from '../route'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import getApps from './getApps'
import queryKey from './queryKey'

function useGetApps(options?: Partial<UseQueryOptions<GetResponseData>>) {
  return useQuery({
    ...options,
    queryKey,
    queryFn: async () => await getApps(),
  })
}

export default useGetApps
