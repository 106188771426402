import type { SpinnerProps } from './Types';
import { memo, useMemo } from 'react';
import isPresent from '@vayapin/utils/isPresent';
import cn from 'lib/utils/cn';
function Spinner({
  visible = true,
  size = 66,
  strokeWidth = 4,
  className
}: Readonly<SpinnerProps>) {
  const radius = useMemo(() => size / 2 - strokeWidth / 2, [size, strokeWidth]); // Calculate radius based on size
  const circumference = useMemo(() => 2 * Math.PI * radius, [radius]); // Calculate the circumference
  const startWidth = useMemo(() => circumference * 0.05, [circumference]);
  const endWidth = useMemo(() => circumference * 0.25, [circumference]);
  const wrapperClasses = useMemo(() => cn({
    ['text-primary-500 transition-opacity']: true,
    [className ?? '']: isPresent(className)
  }, visible ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'), [className, visible]);
  const wrapperStyle = useMemo(() => ({
    width: `${size}px`,
    height: `${size}px`
  }), [size]);
  const spinningSvgStyle = useMemo(() => ({
    animation: `spinner-circle-animation-${size} 1.4s linear infinite`
  }), [size]);
  return <div className={wrapperClasses} style={wrapperStyle} data-sentry-component="Spinner" data-sentry-source-file="Spinner.tsx">
      <style dangerouslySetInnerHTML={{
      __html: `
        @keyframes spinner-circle-animation-${size} {
          0% { stroke-dashoffset: ${circumference}; }
          50% {
            stroke-dasharray: ${endWidth}, ${circumference - endWidth};
            stroke-dashoffset: ${circumference / 2.0};
          }
          100% {
            stroke-dasharray: ${startWidth}, ${circumference - startWidth};
            stroke-dashoffset: 0;
          }
        }
      `
    }} />
      <svg fill="none" viewBox={`0 0 ${size} ${size}`} data-sentry-element="svg" data-sentry-source-file="Spinner.tsx">
        <circle cx={size / 2} cy={size / 2} fill="none" r={radius} stroke='transparent' strokeWidth={strokeWidth} className='stroke-primary-100/50' data-sentry-element="circle" data-sentry-source-file="Spinner.tsx" />
        <circle cx={size / 2} cy={size / 2} fill="none" r={radius} stroke="currentColor" strokeDasharray={`${startWidth}, ${circumference - startWidth}`} strokeDashoffset={0} strokeLinecap="round" strokeMiterlimit={0} strokeWidth={strokeWidth} style={spinningSvgStyle} data-sentry-element="circle" data-sentry-source-file="Spinner.tsx" />
      </svg>
    </div>;
}
export default memo(Spinner);