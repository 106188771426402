import { useEffect, useMemo } from 'react'
import { useSearchParams, useRouter } from 'next/navigation'
import includes from 'lodash/includes'
import useGetAuthInfo from 'app/api/auth/getAuthInfo/_helper/useGetAuthInfo'
import useGetApps from 'app/api/protected/getApps/_helper/useGetApps'
import { useTranslation } from 'lib/i18n/useClientTranslation'
import ClientConfig from 'lib/config/ClientConfig'
import useNextAuthStep from 'lib/auth/useNextAuthStep'

function getSignInPath(inputHost: string) {
  const host = inputHost.replace(/\/+$/, '')
  return `${host}/oauth-direct-sign-in`
}

function useData() {
  const searchParams = useSearchParams()
  const router = useRouter()
  const { t } = useTranslation('screens')

  //
  // Data: load auth info
  const {
    data: authInfo,
    isLoading,
    isFetched,
  } = useGetAuthInfo()

  //
  // Next OAuth step
  const {
    isLoading: isNextAuthStepLoading,
    navigateAfterSignIn,
  } = useNextAuthStep()

  //
  // Data: load apps
  const {
    data: availableAppsData,
    isLoading: isLoadingApps,
  } = useGetApps({ enabled: authInfo?.signedIn })

  //
  // Prepare apps data
  const apps = useMemo(() => {
    const list = [
      {
        key: 'my',
        title: t('home.apps.my'),
        href: getSignInPath(ClientConfig.WEBAPP_HOST ?? ''),
      },
      {
        key: 'admin',
        title: t('home.apps.admin'),
        href: getSignInPath(ClientConfig.ADMIN_HOST ?? ''),
      },
    ]

    return list.filter((app) => includes(availableAppsData?.data, app.key))
  }, [availableAppsData, t])

  //
  // Redirect if not signed in
  useEffect(() => {
    if (!isFetched) return
    if (isLoading) return
    if (authInfo?.signedIn) return

    const newParams = new URLSearchParams(searchParams.toString())

    router.replace(`/sign-in?${newParams.toString()}`)
  }, [authInfo?.signedIn, isFetched, isLoading, router, searchParams])

  //
  // Redirect if required data is missing
  useEffect(() => {
    if (!isFetched) return
    if (isLoading) return
    if (!authInfo?.signedIn) return
    if (authInfo?.userData?.required_data?.length === 0) return

    navigateAfterSignIn({ fallbackToHome: false })
  }, [
    authInfo?.signedIn,
    authInfo?.userData?.required_data?.length,
    isFetched,
    isLoading,
    navigateAfterSignIn
  ])

  //
  // Return
  return useMemo(() => ({
    loading: !isFetched || isLoading || isLoadingApps || isNextAuthStepLoading,
    authId: authInfo?.userData?.email ?? authInfo?.userData?.phone,
    apps,
  }), [
    apps,
    authInfo?.userData?.email,
    authInfo?.userData?.phone,
    isFetched,
    isLoading,
    isLoadingApps,
    isNextAuthStepLoading
  ])
}

export default useData
